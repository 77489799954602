@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-red-100 hover:text-white;
  }
  body {
    @apply min-h-screen relative;
  }
}

@layer components {
  .header {
    @apply bg-red-900 shadow-2xl;
  }

  .header-container {
    @apply container mx-auto px-20 flex justify-between items-center;
  }

  .nav-item {
    @apply inline-block m-4;
  }

  .btn-primary {
    @apply mx-4 my-0 px-4 py-2 rounded-3xl bg-gradient-to-r from-red-500 to-yellow-400 shadow-lg hover:from-red-600 hover:to-yellow-600;
  }

  .footer-item {
    @apply text-yellow-300 text-center;
  }

  .media-item {
    @apply ml-2 text-black text-sm text-red-100 hover:text-white;
  }

  .OCFimg {
    @apply -m-28;
  }
}
