.apply-page-h1 {
    font-family: Noto Sans SC, sans-serif;
    font-size: 48px;

    color: #000000;
}

.apply-page-h2 {
    font-family: Noto Sans SC, sans-serif;
    font-size: 24px;

    color: #000000;
}

.apply-page-p {
    font-family: Noto Sans SC, sans-serif;
    font-size: 18px;

    color: #000000;
}

.apply-page-main-section {
    background-image: url(../images/apply_page_background.png);
    min-height: 743px;
}

.apply-page-main-section h1 {
    font-family: Noto Sans SC, sans-serif;
    font-size: 48px;
}

.apply-page-main-section button {
    font-family: Noto Sans SC, sans-serif;
    font-size: 30px;

    background: linear-gradient(90deg, #EE2F08 -3.39%, rgba(255, 125, 5, 0.42) 103.62%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.apply-page-main-section button:active, .apply-page-main-section button:focus{
    outline: none
}

.apply-page-main-section button:hover {
    background: linear-gradient(90deg, #d32300 -3.39%, rgb(255, 126, 5, 0.753) 103.62%);
}

.apply-page-faq-section {
    background: rgba(142, 142, 254, 0.1);
}

.apply-page-more-q-section {
    background: rgba(227, 227, 227, 0.6);
}

.apply-page-more-q-section .icon{
    height: 107px;
}

.apply-page-more-q-section a{
    color: blue;
}

.apply-page-more-q-section a:hover{
    color: red;
}

.apply-page-more-q-section a:active{
    color: orange;
}