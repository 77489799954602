.front-page-h1 {
    font-size: 36px;
    font-family: Noto Sans SC, sans-serif;
    color: #821802;
}

.front-page-p {
    font-family: Noto Sans SC, sans-serif;
    font-size: 24px;
    word-wrap: break-word;
}

.front-page-button {

    font-family: Noto Sans SC;
    font-weight: bold;
    font-size: 30px;

    color: #FFFFFF;

    background: linear-gradient(269.78deg, #EE2F08 -2.04%, rgba(255, 125, 5, 0.42) 99.85%);
    border-radius: 20px;
    outline: none;
}

.front-page-button:active, .front-page-button:focus {
    outline: none;
}

.front-page-button:hover {
    background: linear-gradient(269.78deg, #da2400 -2.04%, rgba(255, 126, 5, 0.856) 99.85%);
}

.front-page-main-section {
    min-height: 879px;
    background-image: url(../images/background.png);
    background-position: center;
}

.front-page-main-section h1 {
    top: 13%;
    line-height: 1;

    font-family: Abhaya Libre;
    font-size: 64px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    word-wrap: break-word;

}

.front-page-main-section span, .front-page-main-section p{
    top: 41.5%;
}

.front-page-main-section span {
    width: 100%;
    max-width: 270px;
    height: 70px;

    background: rgba(255, 255, 255, 0.72);
}

.front-page-main-section p {
    font-family: Noto Sans SC;
    font-size: 48px;
    word-wrap: break-word;

    background: -webkit-linear-gradient(270deg, rgba(236, 86, 22, 0.8125) 6.54%, rgba(251, 156, 14, 0.83) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.front-page-main-section button {
    top: 55%;
    width: 100%;
    max-width: 221px;
    height: 77px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-size: 30px;
    color: #FFFFFF;
    word-wrap: break-word;

    background: linear-gradient(90deg, #EE2F08 -3.39%, rgba(255, 125, 5, 0.42) 103.62%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

    border-radius: 30px;
}

.front-page-main-section button:active, .front-page-main-section button:focus{
    outline: none
}

.front-page-main-section button:hover {
    background: linear-gradient(90deg, #d32300 -3.39%, rgba(255, 126, 5, 0.753) 103.62%);
}

.front-page-mailing-section {
    background-color: rgba(142, 142, 254, 0.1);
}

.front-page-about-section .description {
    padding: 0% 20% 0% 20%;
}

.front-page-about-section p {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 24px;
    
}

.front-page-about-section .container2 {
    padding-left: 7%;
    padding-right: 7%;
}

.front-page-about-section h1 {
    color: #821802;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
}

.front-page-about-section h2{
    font-family: Montserrat, sans-serif;
    font-size: 27px;
}

@media(min-width: 768px) {
    .front-page-about-section h2{
        min-width: 134px;
    }

    .front-page-about-section p{
        font-size: 32px;
    }
}