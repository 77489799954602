p {
  font-family: Noto Sans SC, sans-serif;
  font-size: 24px;
  word-wrap: break-word;
}

.main-section-about {
  min-height: 879px;
  background-position: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/AboutPageBackground.png);
  background-size: cover;
}

.main-section-about h1 {
  top: 13%;
  line-height: 1;

  font-family: Abhaya Libre;
  font-size: 64px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  word-wrap: break-word;
}

.main-section-about span,
.main-section-about p {
  top: 41.5%;
}

.main-section-about span {
  width: 100%;
  max-width: 270px;
  height: 70px;

  background: rgba(255, 255, 255, 0.72);
}

.main-section-about p {
  font-family: Noto Sans SC;
  font-size: 48px;
  word-wrap: break-word;

  background: -webkit-linear-gradient(
    270deg,
    rgba(236, 86, 22, 0.8125) 6.54%,
    rgba(251, 156, 14, 0.83) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-section button {
  top: 55%;
  width: 100%;
  max-width: 221px;
  height: 77px;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-size: 30px;
  color: #ffffff;
  word-wrap: break-word;

  background: linear-gradient(
    90deg,
    #ee2f08 -3.39%,
    rgba(255, 125, 5, 0.42) 103.62%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

  border-radius: 30px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
}
.vertical {
  display: flex;
  flex-direction: column;
}

.topline {
  margin-left: auto;
  margin-right: auto;
  height: 15px;
  background-color: #d4af37;
  opacity: 0.5;
}

.underline {
  margin-left: auto;
  margin-right: auto;
  height: 3px;
  background-color: #d4af37;
  width: 40px;
}

.department {
  text-align: center;
  font-weight: bold;
  font-size: 40px;
}

h4 {
  width: 100%;
  text-align: center;
  color: #d4af37;
  font-weight: bold;
  font-size: 40px;
  border-bottom: 2px solid #d4af37;
  line-height: 0.1em;
  margin: 30px 0 20px;
}

h4 span {
  background: #fff;
  padding: 0 10px;
}

.content {
  text-align: center;
}
